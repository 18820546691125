import { FC, ReactNode } from 'react';
import { ButtonBase, ButtonBaseProps, useTheme } from '@mui/material';

interface DriveButtonProps extends ButtonBaseProps {
  icon: ReactNode;
  text: string;
}

const DriveButton: FC<DriveButtonProps> = ({ icon, text, disabled, ...props }) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      sx={{
        display: 'grid',
        gridTemplateRows: '48px min-content',
        justifyItems: 'center',
        gap: 1,
        width: 150,
        height: '100%',
        p: 1,
        fontSize: 'body3.fontSize',
        fontWeight: 'bold',
        borderRadius: 2,
        color: palette.controls.text,
        opacity: disabled ? 0.4 : 1,
      }}
      disabled={disabled}
      {...props}
    >
      {icon}
      {text}
    </ButtonBase>
  );
};

export default DriveButton;
