import PSPDFKit from 'pspdfkit';
import { MutableRefObject } from 'react';
import { usePspdfkit } from '@/containers/DocumentEditor/internalHooks/usePspdfkit';
import { CompareSources, ComparisonSource } from '@/containers/DocumentEditor/types';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { useProject } from '@/views/Project/hooks/useProject';

type Params = {
  isEnabled?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  documentBeforeCompare?: ComparisonSource | null;
  compareSources?: CompareSources | null;
};

const URL_STORE_TIME = 1000 * 60 * 20; // 20 minutes

export const useComparePspdf = ({ isEnabled, containerRef, documentBeforeCompare, compareSources }: Params) => {
  const { projectSlug } = useProject();

  const { data: activeDocument, isLoading: activeDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: documentBeforeCompare ? documentBeforeCompare.documentId! : 'fake' },
    { query: { enabled: isEnabled && !!documentBeforeCompare, staleTime: URL_STORE_TIME } },
  );

  const { data: firstDocument, isLoading: firstDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: compareSources ? compareSources.firstSource.documentId! : 'fake' },
    { query: { enabled: isEnabled && !!compareSources?.firstSource, staleTime: URL_STORE_TIME } },
  );

  const { data: secondDocument, isLoading: secondDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: compareSources ? compareSources.secondSource.documentId! : 'fake' },
    { query: { enabled: isEnabled && !!compareSources?.secondSource, staleTime: URL_STORE_TIME } },
  );

  const isCompareEnabled =
    !!isEnabled && Boolean((activeDocument && documentBeforeCompare) || (firstDocument && secondDocument && compareSources));
  const key =
    activeDocument && documentBeforeCompare
      ? `${activeDocument._id}_${documentBeforeCompare.page}`
      : firstDocument && secondDocument && compareSources
        ? `${firstDocument._id}_${secondDocument._id}_${compareSources?.autoCompare}_${compareSources.firstSource.page}_${compareSources.secondSource.page}`
        : undefined;
  const url =
    activeDocument?.urls && documentBeforeCompare
      ? activeDocument.urls[documentBeforeCompare.page]
      : firstDocument?.urls && compareSources
        ? firstDocument.urls[compareSources.firstSource.page]
        : undefined;

  const { isLoading, unload } = usePspdfkit({
    isEnabled: isCompareEnabled,
    key,
    containerRef,
    url,
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(new PSPDFKit.ViewState({ showToolbar: false })),
    },
    onLoad: async (instance, controller) => {
      if (
        documentBeforeCompare ||
        !instance ||
        !compareSources?.firstSource ||
        !compareSources?.secondSource ||
        !firstDocument?.urls ||
        !secondDocument?.urls
      )
        return;

      try {
        const secondUrl = secondDocument.urls[compareSources.secondSource.page];
        const source = await fetch(secondUrl, { signal: controller.signal }).then(response => response.arrayBuffer());
        instance.setDocumentComparisonMode({
          documentA: { source: 'USE_OPEN_DOCUMENT', pageIndex: 0 },
          documentB: { source: source, pageIndex: 0 },
          autoCompare: compareSources.autoCompare,
        });
      } catch (error) {
        console.error('Error while loading source', error);
        /* empty */
      }
    },
  });

  return {
    isLoading: activeDocumentIsLoading || firstDocumentIsLoading || secondDocumentIsLoading || isLoading,
    unload,
  };
};
