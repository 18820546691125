import { MouseEvent, useState } from 'react';
import { DriveFile, DriveFileType } from '@/types';
import { ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { DocumentMetadata } from '@/api/generated';

type Params = {
  document?: DocumentMetadata;
  importDriveFiles: ImportDriveFilesFn;
};

export const useDrivesImport = ({ document, importDriveFiles }: Params) => {
  const [openedDriveFilesType, setOpenedDriveFilesType] = useState<'none' | DriveFileType>('none');

  const onOneDriveClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenedDriveFilesType('onedrive');
  };

  const onGoogleDriveClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenedDriveFilesType('google');
  };

  const onFileSelect = async (file: DriveFile) => {
    if (openedDriveFilesType === 'none') return;
    importDriveFiles([file], { type: openedDriveFilesType, versionedFileId: document?._id });
    setOpenedDriveFilesType('none');
  };

  return { openedDriveFilesType, setOpenedDriveFilesType, onOneDriveClick, onGoogleDriveClick, onFileSelect };
};
