import { createContext } from 'react';
import { AnnotationBackendJSON, ISidebarMode } from 'pspdfkit';
import {
  ComparisonOptions,
  ComparisonSource,
  CountingMode,
  EditorMode,
  JumpToParams,
  UpdatePspdfAnnotations,
} from '@/containers/DocumentEditor/types';
import { ConstructingEquipmentPiece } from '@/api/generated';
import { type useMultiCountTool } from '@/containers/DocumentEditor/internalHooks/useMultiCountTool';
import { type useMakeToolbarAction } from '@/containers/DocumentEditor/internalHooks/useMakeToolbarAction';
import { type useSubscribeToViewStateChange } from '@/containers/DocumentEditor/internalHooks/useSubscribeToViewStateChange';
import { type useAutoCountTool } from '@/containers/DocumentEditor/internalHooks/useAutoCountTool';
import { type useToolbarCategory } from '@/containers/DocumentEditor/internalHooks/useToolbarCategory';

type EditorContextValue = {
  setDocumentEditorContainerRef: (element: HTMLDivElement) => void;
  setCompareContainerRef: (element: HTMLDivElement) => void;
  setSingleContainerRef: (element: HTMLDivElement) => void;
  isDocumentEditorEnabled: boolean;
  isDocumentEditorLoading: boolean;
  isCompareLoading: boolean;
  isSingleLoading: boolean;
  loadingPercent: number;
  singleLoadingPercent: number;

  editorMode: EditorMode;
  showAnnotationsEditor: () => void;
  disabledEditor: () => void;
  showDocumentBeforeCompare: (source: ComparisonSource) => void;
  compareDocuments: (firstSource: ComparisonSource, secondSource: ComparisonSource, options: ComparisonOptions) => void;
  showSingleDocumentWithAnnotations: (documentId: string, annotations: AnnotationBackendJSON[]) => void;

  countingMode: CountingMode;
  sidebarMode: ISidebarMode | 'FAVORITES' | null;
  toggleSidebarMode: (sidebarMode: 'THUMBNAILS' | 'DOCUMENT_OUTLINE' | 'FAVORITES') => void;

  page: number;
  totalPagesCount: number;
  setPage: (page: number) => void;
  jumpToBox: (params: JumpToParams) => void;

  selectedEquipmentItems: ConstructingEquipmentPiece[] | null;
  updatePspdfAnnotations: UpdatePspdfAnnotations;

  multiCountColor: ReturnType<typeof useMultiCountTool>['multiCountColor'];
  setMultiCountColor: ReturnType<typeof useMultiCountTool>['setMultiCountColor'];
  openMultiCountTool: ReturnType<typeof useMultiCountTool>['openMultiCountTool'];

  toolbarCategory: ReturnType<typeof useToolbarCategory>['toolbarCategory'];
  setToolbarCategory: ReturnType<typeof useToolbarCategory>['setToolbarCategory'];
  setCategoryInsidePreset: ReturnType<typeof useToolbarCategory>['setCategoryInsidePreset'];

  autoCountingTempAnnotation: ReturnType<typeof useAutoCountTool>['autoCountingTempAnnotation'];
  closeAutoCountTool: ReturnType<typeof useAutoCountTool>['closeAutoCountTool'];
  saveAutoCountAnnotation: ReturnType<typeof useAutoCountTool>['saveAutoCountAnnotation'];

  makeToolbarAction: ReturnType<typeof useMakeToolbarAction>;
  subscribeToViewStateChange: ReturnType<typeof useSubscribeToViewStateChange>;
};

export const EditorContext = createContext<EditorContextValue | null>(null);
