import { Tab, useTheme } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { TabList } from '@mui/lab';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FC, SyntheticEvent } from 'react';
import { TABS } from '@/views/Knowledge/components/consts';

export interface TabsValues {
  iconName?: IconNames;
  value: TABS;
}

interface TabsProps {
  handleTabChange: (event: SyntheticEvent, newValue: TABS) => void;
  tabs: TabsValues[];
}

const Tabs: FC<TabsProps> = ({ handleTabChange, tabs }) => {
  const { palette, spacing } = useTheme();
  const { t } = useTranslation('common');

  const commonStyles: SxProps = {
    display: 'grid',
    columnGap: 0.5,
    pb: 0.5,
    fontSize: 'body2.fontSize',
    textTransform: 'unset',
    color: palette.grey[700],
    '& > .MuiTab-iconWrapper': { mb: 0 },
  };

  return (
    <TabList
      sx={{ width: '100%', px: 0, '& .MuiTabs-flexContainer': { gap: `min(${spacing(5)}, 5%)` } }}
      onChange={handleTabChange}
      aria-label={t('knowledge.label')}
    >
      {tabs.map(({ iconName, value }) => (
        <Tab
          key={value}
          title={t(`knowledge.knowledgeTabs.${value}`)}
          disableRipple
          icon={iconName && <Icon name={iconName} />}
          sx={commonStyles}
          label={t(`knowledge.knowledgeTabs.${value}`)}
          value={value}
        />
      ))}
    </TabList>
  );
};

export default Tabs;
