import { useTheme } from '@mui/material';
import TextInput from '@/components/TextInput/TextInput';
import { LoadingButton } from '@mui/lab';
import Icon from '@/components/Icon/Icon';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { FC } from 'react';
import { FormCreateOrUpdateMemo } from '@/views/Knowledge/components/Memos';

interface MemoFormProps {
  control: Control<FormCreateOrUpdateMemo>;
  disabled: boolean;
  isSubmitting: boolean;
  onChange: () => void;
  onSubmit: () => void;
}

const MemoForm: FC<MemoFormProps> = ({ onSubmit, control, onChange, isSubmitting, disabled }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('common');

  const commonTextInputStyle = {
    '& .MuiInputBase-root': {
      height: '100%',
      py: 1.75,
      px: 2,
      backgroundColor: palette.background.default,
      borderRadius: 1,
      border: `1px solid ${palette.grey[600]}`,
    },
  };

  return (
    <Stack
      gap={2}
      component="form"
      onSubmit={onSubmit}
      sx={{
        position: 'relative',
        height: '100%',
        '& .MuiFormControl-root': { flex: 1 },
        borderRadius: 1,
        flex: 1,
      }}
    >
      <TextInput
        control={control}
        placeholder={t('knowledge.memos.rightSide.titlePlaceholder')}
        name="title"
        id="memoTitle"
        sx={commonTextInputStyle}
        inputProps={{
          sx: {
            p: 0,
          },
        }}
        onChange={onChange}
      />
      <TextInput
        sx={{
          ...commonTextInputStyle,
          height: '100%',
        }}
        inputProps={{
          sx: {
            height: '100% !important',
            p: 0,
          },
        }}
        multiline
        control={control}
        placeholder={t('knowledge.memos.rightSide.contentPlaceholder')}
        name="content"
        id="memoContent"
        onChange={onChange}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        disabled={!disabled}
        variant="contained"
        title={t('knowledge.memos.button.title')}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          m: 1,
          borderRadius: '999px',
          width: 'auto',
          maxWidth: 'auto',
          minWidth: 'auto',
          p: 1,
        }}
      >
        <Icon name="save" />
      </LoadingButton>
    </Stack>
  );
};

export default MemoForm;
