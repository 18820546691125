import { useCallback, useEffect, useState } from 'react';
import PSPDFKit, { AnnotationsUnion, Color, Instance, Rect } from 'pspdfkit';
import { CountingMode, SetToolCategoryOptions, UpdatePspdfAnnotations } from '@/containers/DocumentEditor/types';
import { ConstructingEquipmentPiece, EquipmentDescriptionSearch, UnitType } from '@/api/generated';
import uniq from 'lodash/uniq';

type Params = {
  instance?: Instance;
  instanceRoot?: HTMLDivElement | null;
  mode: CountingMode;
  setMode: (mode: CountingMode) => void;
  selectedAnnotations: AnnotationsUnion[] | null;
  selectedEquipmentItems: ConstructingEquipmentPiece[] | null;
  toolbarCategory: EquipmentDescriptionSearch | null;
  setToolbarCategory: (category: EquipmentDescriptionSearch | null, options: SetToolCategoryOptions) => void;
  updatePspdfAnnotations: UpdatePspdfAnnotations;
};

export const useMultiCountTool = ({
  instance,
  instanceRoot,
  mode,
  setMode,
  selectedAnnotations,
  selectedEquipmentItems,
  toolbarCategory,
  setToolbarCategory,
  updatePspdfAnnotations,
}: Params) => {
  const [color, setColorState] = useState<string>(Color.BLUE.toHex());

  const setColor = (nextColor: string) => {
    if (selectedAnnotations) {
      const pspdfColor = PSPDFKit.Color.fromHex(nextColor);
      const updatedAnnotations: AnnotationsUnion[] = selectedAnnotations.map(annotation =>
        annotation.set('fillColor', pspdfColor).set('strokeColor', pspdfColor),
      );
      updatePspdfAnnotations({ eventType: 'update', annotations: updatedAnnotations });
    }

    setColorState(nextColor);
  };

  const openMultiCountTool = useCallback(
    (category: EquipmentDescriptionSearch | null = null) => {
      setMode(CountingMode.MULTI_COUNTING);
      instance?.setViewState(viewState => viewState.set('interactionMode', null));
      setToolbarCategory(category, { toolType: UnitType.pieces });
    },
    [instance, setToolbarCategory],
  );

  useEffect(() => {
    if (!selectedEquipmentItems) return;

    const itemsColors = uniq(selectedEquipmentItems.flatMap(item => item.iconColors));
    setColorState(itemsColors.length === 1 ? itemsColors[0] : Color.WHITE.toHex());
  }, [selectedEquipmentItems]);

  useEffect(() => {
    if (!instance) return;

    const handleMultiCountingEvent = async (event: globalThis.MouseEvent) => {
      if (mode !== CountingMode.MULTI_COUNTING || !toolbarCategory || !instance) return;

      const target = event.target as HTMLElement;
      const clickedOnPage = target.classList.contains('PSPDFKit-Page') || target.closest('.PSPDFKit-Page');
      if (!clickedOnPage) return;

      const rect = new PSPDFKit.Geometry.Rect({
        left: event.clientX - 13,
        top: event.clientY - 13,
        width: 20,
        height: 20,
      });

      const boundingBox = instance.transformContentClientToPageSpace(rect, instance.viewState.currentPageIndex) as Rect;
      const annotationColor = color ? PSPDFKit.Color.fromHex(color) : Color.BLUE;

      const newAnnotation = new PSPDFKit.Annotations.EllipseAnnotation({
        pageIndex: instance.viewState.currentPageIndex,
        boundingBox,
        fillColor: annotationColor,
        strokeColor: annotationColor,
        opacity: 0.34,
        customData: {
          specialType: CountingMode.MULTI_COUNTING,
          groupAnnotationKey: toolbarCategory.equipment_id,
        },
      });

      void instance.create(newAnnotation);
    };

    instanceRoot?.addEventListener('click', handleMultiCountingEvent);

    return () => {
      instanceRoot?.removeEventListener('click', handleMultiCountingEvent);
    };
  }, [instance, mode, toolbarCategory, color]);

  useEffect(() => {
    instanceRoot?.classList.toggle('PSPDFKit-Root__mode-multi-counting', mode === CountingMode.MULTI_COUNTING);
  }, [instanceRoot, mode]);

  return { multiCountColor: color, setMultiCountColor: setColor, openMultiCountTool };
};
