import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { EditorContext } from '@/containers/DocumentEditor/contexts/EditorContext';
import { useTranslation } from 'react-i18next';
import {
  CompareSources,
  ComparisonOptions,
  ComparisonSource,
  CountingMode,
  EditorMode,
  JumpToParams,
  UpdatePspdfAnnotations,
} from '@/containers/DocumentEditor/types';
import { useUpdateAnnotations } from '@/views/Project/views/SideBySide/hooks/useUpdateAnnotations';
import PSPDFKit, { AnnotationBackendJSON, AnnotationsUnion, Instance, PublicTextSelection } from 'pspdfkit';
import { focusOnConfirmButtonOnAnnotationDelete } from '@/containers/DocumentEditor/utils/focusOnConfirmButtonOnAnnotationDelete';
import { keepSelectingToolForPerimeterAndPolygon } from '@/containers/DocumentEditor/utils/keepSelectingToolForPerimeterAndPolygon';
import nextTick from '@/services/nextTick';
import arrowIcon from '@/assets/arrow-down.svg?raw';
import { keepSelectingToolForMeasurements } from '@/containers/DocumentEditor/utils/keepSelectingToolForMeasurements';
import { togglePanAndScrollOnScrollModeChange } from '@/containers/DocumentEditor/utils/togglePanAndScrollOnScrollModeChange';
import { useDocumentEditor } from '@/containers/DocumentEditor/internalHooks/useDocumentEditor';
import { useAnnotationsByType } from '@/containers/DocumentEditor/internalHooks/useAnnotationsByType';
import { useSelectedAnnotations } from '@/containers/DocumentEditor/internalHooks/useSelectedAnnotations';
import { useToolbarCategory } from '@/containers/DocumentEditor/internalHooks/useToolbarCategory';
import { useScaleSelect } from '@/containers/DocumentEditor/internalHooks/useScaleSelect';
import { useFullscreenZoom } from '@/containers/DocumentEditor/internalHooks/useFullscreenZoom';
import { useModeResetOnEsc } from '@/containers/DocumentEditor/internalHooks/useModeResetOnEsc';
import { useAnnotationsEvent } from '@/containers/DocumentEditor/internalHooks/useAnnotationsEvent';
import { useMeasurementDrag } from '@/containers/DocumentEditor/internalHooks/useMeasurementDrag';
import { useRightMousePan } from '@/containers/DocumentEditor/internalHooks/useRightMousePan';
import { useAnnotationsStyling } from '@/containers/DocumentEditor/internalHooks/useAnnotationsStyling';
import { useAnnotationsDiff } from '@/containers/DocumentEditor/internalHooks/useAnnotationsDiff';
import { useMultiCountTool } from '@/containers/DocumentEditor/internalHooks/useMultiCountTool';
import { useAutoCountTool } from '@/containers/DocumentEditor/internalHooks/useAutoCountTool';
import { useMakeToolbarAction } from '@/containers/DocumentEditor/internalHooks/useMakeToolbarAction';
import { useKeyboardShortcuts } from '@/containers/DocumentEditor/internalHooks/useKeyboardShortcuts';
import { usePageChangeEvent } from '@/containers/DocumentEditor/internalHooks/usePageChangeEvent';
import { useDispatchRootClickEvent } from '@/containers/DocumentEditor/internalHooks/useDispatchRootClickEvent';
import { useNoInteractionsWithoutCategory } from '@/containers/DocumentEditor/internalHooks/useNoInteractionsWithoutCategory';
import AutoCountingSettingsDialog from '@/containers/DocumentEditor/components/AutoCountingSettingsDialog';
import { useAnnotations } from '@/views/Project/hooks/useAnnotations';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { useLayoutState } from '@/views/Project/hooks/useLayoutState';
import { useSubscribeToViewStateChange } from '@/containers/DocumentEditor/internalHooks/useSubscribeToViewStateChange';
import { useInstanceViewState } from '@/containers/DocumentEditor/internalHooks/useInstanceViewState';
import ScaleSelectDialog from '@/containers/DocumentEditor/components/ScaleSelectDialog';
import { useComparePspdf } from '@/containers/DocumentEditor/internalHooks/useComparePspdf';
import { useSinglePspdf } from '@/containers/DocumentEditor/internalHooks/useSinglePspdf';

interface EditorContextProviderProps {
  children: ReactNode;
}

const EditorContextProvider: FC<EditorContextProviderProps> = ({ children }) => {
  const { t } = useTranslation('editor');
  const documentEditorContainerRef = useRef<HTMLDivElement | null>(null);
  const compareContainerRef = useRef<HTMLDivElement | null>(null);
  const singleContainerRef = useRef<HTMLDivElement | null>(null);
  const selectedText = useRef('');
  const [mode, setMode] = useState<CountingMode>(CountingMode.REGULAR);
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [editorMode, setEditorMode] = useState<EditorMode>('annotations');
  const [singleDocumentId, setSingleDocumentId] = useState<string | undefined>(undefined);
  const [singleModeAnnotations, setSingleModeAnnotations] = useState<AnnotationBackendJSON[]>([]);
  const [documentBeforeCompare, setDocumentBeforeCompare] = useState<ComparisonSource | null>(null);
  const [documentsToCompare, setDocumentsToCompare] = useState<CompareSources | null>(null);
  const updateAnnotations = useUpdateAnnotations();

  const { annotations, equipmentList, isLoading, hiddenAnnotationsIds, highlightedAnnotationsIds } = useAnnotations();
  const { document, isLoadingDocument } = useDocuments();
  const { isFullScreen } = useLayoutState();

  const isDocumentEditorEnabled = !isLoading && !isLoadingDocument && editorMode === 'annotations';

  const onPspdfkitLoad = (instance: Instance) => {
    window.INSTANCE_LOADED = false;

    focusOnConfirmButtonOnAnnotationDelete(instance);
    instance.addEventListener('textSelection.change', async (textSelection: PublicTextSelection | null) => {
      if (!textSelection) return;
      selectedText.current = await textSelection.getText();
    });

    keepSelectingToolForPerimeterAndPolygon({ instance: instance });
    instance.addEventListener('viewState.change', (viewState, prevViewState) => {
      viewState.interactionMode && setMode(CountingMode.REGULAR);
      // If we change viewState inside event without tick,
      // We get wrong event order fired inside other handlers.
      nextTick(() => {
        keepSelectingToolForMeasurements({ instance: instance, viewState, prevViewState });
        togglePanAndScrollOnScrollModeChange({ instance: instance, viewState });
      });
    });

    instance.setAnnotationToolbarItems((_, { defaultAnnotationToolbarItems }) => [
      {
        type: 'custom',
        id: 'back-button',
        title: t('toolbarCloseButton'),
        className: 'closeButton',
        icon: arrowIcon,
        onPress: () => instance.setViewState(viewState => viewState.set('interactionMode', null)),
      },
      ...defaultAnnotationToolbarItems,
    ]);

    setTotalPagesCount(instance.totalPageCount);
  };

  const {
    instance,
    instanceRef,
    scalesRef,
    lastPrevConfigRef,
    setInitialConfig,
    isLoading: isDocumentEditorLoading,
    loadingPercent,
    unload: unloadDocument,
  } = useDocumentEditor({
    containerRef: documentEditorContainerRef,
    documentUrl: document?.url,
    annotations,
    isEnabled: isDocumentEditorEnabled,
    onLoad: onPspdfkitLoad,
    onInlineTextSelectionPress: () => window.open(t('webSearch.url', { query: selectedText.current })),
  });

  const { isLoading: isCompareLoading, unload: unloadCompare } = useComparePspdf({
    isEnabled: editorMode === 'compare',
    containerRef: compareContainerRef,
    documentBeforeCompare,
    compareSources: documentsToCompare,
  });

  const {
    instance: singleModeInstance,
    isLoading: isSingleLoading,
    loadingPercent: singleLoadingPercent,
    unload: unloadSingleDocument,
  } = useSinglePspdf({
    isEnabled: editorMode === 'single',
    containerRef: singleContainerRef,
    documentId: singleDocumentId,
    annotations: singleModeAnnotations,
  });

  const subscribeToViewStateChange = useSubscribeToViewStateChange(instance);
  const { sidebarMode } = useInstanceViewState(['sidebarMode'], {
    isEnabled: isDocumentEditorEnabled,
    subscribeToViewStateChange,
  });

  const instanceRoot = useMemo(() => instance?.contentDocument.querySelector<HTMLDivElement>('.PSPDFKit-Root'), [instance]);
  const toggleInteractions = useCallback(
    (newState: boolean) => {
      instance?.contentDocument.querySelector('.PSPDFKit-Viewport')?.classList.toggle('interactions-disabled', !newState);
    },
    [instanceRoot],
  );

  const getAnnotationsByIds = async (ids: string[]) => {
    if (!instance) return [];

    let list: AnnotationsUnion[] = [];
    for (let i = 0; i < instance.totalPageCount; i++) {
      const pageAnnotations = await instance.getAnnotations(i);
      list = list.concat(pageAnnotations.filter(annotation => ids.includes(annotation.id)).toArray());

      if (list.length === ids.length) break;
    }

    return list;
  };

  const updatePspdfAnnotations: UpdatePspdfAnnotations = async ({
    eventType,
    ids,
    annotations: annotationsToUpdate,
    callback,
  }) => {
    if (!instance) return;

    const typedAnnotations =
      // When we create annotations is okay to have AnnotationBackendJSON type
      eventType === 'create' && annotationsToUpdate
        ? (annotationsToUpdate as unknown as AnnotationsUnion[])
        : annotationsToUpdate;

    if (typedAnnotations) return instance[eventType](typedAnnotations);
    if (eventType === 'delete' && ids && !callback) return instance.delete(ids);

    const mappedAnnotations = ids && callback ? (await getAnnotationsByIds(ids)).map(callback) : null;
    if (mappedAnnotations) return instance[eventType](mappedAnnotations);

    console.warn("'updatePspdfAnnotations haven't found annotations to update");
  };

  const annotationsByType = useAnnotationsByType({ equipmentList });
  const { selectedEquipmentItems, selectedAnnotations } = useSelectedAnnotations({ instance: instance, annotationsByType });
  const { toolbarCategory, setToolbarCategory, setCategoryInsidePreset } = useToolbarCategory({
    instance: instance,
    selectedAnnotations,
    selectedEquipmentItems,
    updatePspdfAnnotations,
  });

  const setInitialMode = () => {
    setMode(CountingMode.REGULAR);
  };

  const toggleSidebarMode = (nextSidebarMode: 'THUMBNAILS' | 'DOCUMENT_OUTLINE' | 'FAVORITES') => {
    if (!instance) return;

    if (nextSidebarMode === 'FAVORITES') {
      instance.setViewState(viewState => viewState.set('sidebarMode', null));
      setIsFavoritesOpen(!isFavoritesOpen);
    } else {
      setIsFavoritesOpen(false);
      instance.setViewState(viewState =>
        viewState.set('sidebarMode', viewState.sidebarMode !== nextSidebarMode ? nextSidebarMode : null),
      );
    }
  };

  useFullscreenZoom({ instance: instance, isFullScreen });
  useModeResetOnEsc({ instance: instance, setInitialMode });
  useAnnotationsEvent({ instance: instance, scalesRef, lastPrevConfigRef, updateAnnotations });
  useMeasurementDrag({ instance: instance });
  useRightMousePan({ instance: instance });

  useAnnotationsStyling({
    instance: instance,
    hiddenAnnotationsIds,
    highlightedAnnotationsIds,
  });

  useAnnotationsDiff({
    instance: instance,
    annotations,
    isEnabled: isDocumentEditorEnabled && Boolean(instanceRef.current),
  });

  const { multiCountColor, setMultiCountColor, openMultiCountTool } = useMultiCountTool({
    instance: instance,
    instanceRoot,
    mode,
    setMode,
    selectedAnnotations,
    selectedEquipmentItems,
    toolbarCategory,
    setToolbarCategory,
    updatePspdfAnnotations,
  });

  const { autoCountingTempAnnotation, openAutoCountTool, closeAutoCountTool, saveAutoCountAnnotation } = useAutoCountTool({
    instance: instance,
    mode,
    setMode,
    setInitialMode,
  });

  const makeToolbarAction = useMakeToolbarAction({
    instance: instance,
    documentFilename: document?.filename,
    openAutoCountTool,
    openMultiCountTool,
    setToolbarCategory,
    setMultiCountColor,
  });

  useKeyboardShortcuts({
    instance: instance,
    mode,
    makeToolbarAction,
  });

  usePageChangeEvent({
    instance: instance,
    page,
    onPage: setPage,
  });

  useDispatchRootClickEvent(instanceRoot);
  useNoInteractionsWithoutCategory({
    instance: instance,
    mode,
    toolbarCategory,
    toggleInteractions,
  });

  const setEditorPage = (nextPage: number) => {
    const currentInstance = editorMode === 'single' ? singleModeInstance : instance;
    const isEnabled = editorMode === 'single' ? !isSingleLoading : isDocumentEditorEnabled;
    if (!currentInstance || nextPage === currentInstance.viewState.currentPageIndex || !isEnabled) return;

    currentInstance.setViewState(viewState => viewState.set('currentPageIndex', nextPage));
    setPage(nextPage);
  };

  const jumpToBox = ({ pageIndex, bbox, zoomIn }: JumpToParams) => {
    const currentInstance = editorMode === 'single' ? singleModeInstance : instance;
    if (!currentInstance) return;

    const [left, top, width, height] = bbox;
    const rect = new PSPDFKit.Geometry.Rect({ left, top, width, height });
    zoomIn ? currentInstance.jumpAndZoomToRect(pageIndex, rect) : currentInstance.jumpToRect(pageIndex, rect);

    // jumpToRect don't do anything if we see at least some part of bbox,
    // So in that case is better to change page to see annotation better.
    setEditorPage(pageIndex);
  };

  const { isScaleDialogOpened, closeScaleDialog, addScaleConfig } = useScaleSelect({
    instance: instance,
    scalesRef,
    setInitialConfig,
  });

  const setDocumentEditorContainerRef = (element: HTMLDivElement | null) => {
    documentEditorContainerRef.current = element ?? documentEditorContainerRef.current;
  };

  const setCompareContainerRef = (element: HTMLDivElement | null) => {
    compareContainerRef.current = element ?? compareContainerRef.current;
  };

  const setSingleContainerRef = (element: HTMLDivElement | null) => {
    singleContainerRef.current = element ?? singleContainerRef.current;
  };

  const resetGeneralEditor = () => {
    unloadDocument();
  };

  const resetCompareEditor = () => {
    unloadCompare();
    setDocumentsToCompare(null);
    setDocumentBeforeCompare(null);
  };

  const resetSingleEditor = () => {
    unloadSingleDocument();
    setSingleDocumentId(undefined);
    setSingleModeAnnotations([]);
  };

  const showAnnotationsEditor = () => {
    setEditorMode('annotations');
    setPage(0);
    resetCompareEditor();
    resetSingleEditor();
  };

  const disabledEditor = () => {
    setEditorMode('hidden');
    resetGeneralEditor();
    resetCompareEditor();
    resetSingleEditor();
  };

  const showDocumentBeforeCompare = (source: ComparisonSource) => {
    setEditorMode('compare');
    resetGeneralEditor();
    resetSingleEditor();
    setDocumentsToCompare(null);
    setDocumentBeforeCompare(source);
  };

  const showSingleDocumentWithAnnotations = (documentId: string, annotationsToShow: AnnotationBackendJSON[]) => {
    setEditorMode('single');
    setPage(0);
    resetGeneralEditor();
    resetCompareEditor();
    setSingleDocumentId(documentId);
    setSingleModeAnnotations(annotationsToShow);
  };

  const compareDocuments = (
    firstSource: ComparisonSource,
    secondSource: ComparisonSource,
    { autoCompare }: ComparisonOptions,
  ) => {
    setEditorMode('compare');
    resetGeneralEditor();
    resetSingleEditor();
    setDocumentBeforeCompare(null);
    setDocumentsToCompare({ firstSource, secondSource, autoCompare });
  };

  return (
    <EditorContext.Provider
      value={{
        setDocumentEditorContainerRef,
        setCompareContainerRef,
        setSingleContainerRef,
        isDocumentEditorEnabled,
        isDocumentEditorLoading,
        isCompareLoading,
        isSingleLoading,
        loadingPercent,
        singleLoadingPercent,

        editorMode,
        showAnnotationsEditor,
        showDocumentBeforeCompare,
        disabledEditor,
        compareDocuments,
        showSingleDocumentWithAnnotations,

        countingMode: mode,
        sidebarMode: sidebarMode ?? (isFavoritesOpen ? 'FAVORITES' : null),
        toggleSidebarMode,

        page,
        totalPagesCount,
        setPage: setEditorPage,
        jumpToBox,

        selectedEquipmentItems,
        updatePspdfAnnotations,

        multiCountColor,
        setMultiCountColor,
        openMultiCountTool,

        toolbarCategory,
        setToolbarCategory,
        setCategoryInsidePreset,

        autoCountingTempAnnotation,
        closeAutoCountTool,
        saveAutoCountAnnotation,

        makeToolbarAction,
        subscribeToViewStateChange,
      }}
    >
      {children}
      <AutoCountingSettingsDialog
        isOpened={!!document && !!autoCountingTempAnnotation && mode === CountingMode.AUTO_COUNTING}
        currentPage={page}
        document={document}
        onSave={saveAutoCountAnnotation}
        onClose={closeAutoCountTool}
      />
      <ScaleSelectDialog isOpen={isScaleDialogOpened} onClose={closeScaleDialog} onAddScaleConfig={addScaleConfig} />
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
