import { Box, Stack, SvgIcon, useTheme } from '@mui/material';
import { DropzoneState } from 'react-dropzone';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DropZoneArea from '@/components/DropZoneArea';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import ProcoreIcon from '@/assets/procore.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import CloudFilesDialog from '@/views/Projects/components/ProjectFormDialog/components/CloudFilesDialog';
import DriveButton from '@/views/Projects/components/ProjectFormDialog/components/DriveButton';
import { ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { useDrivesImport } from '@/views/Projects/components/ProjectFormDialog/hooks/useDrivesImport';

interface DocumentUploadProps extends Pick<DropzoneState, 'getInputProps' | 'isDragActive' | 'getRootProps'> {
  importDriveFiles: ImportDriveFilesFn;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ getInputProps, isDragActive, getRootProps, importDriveFiles }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();

  const { openedDriveFilesType, setOpenedDriveFilesType, onOneDriveClick, onGoogleDriveClick, onFileSelect } = useDrivesImport({
    importDriveFiles,
  });

  return (
    <>
      <DropZoneArea sx={{ minHeight: 0, mb: 1.5, p: 1 }} isDragActive={isDragActive} {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack gap={1.5} alignItems="center" p={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SvgIcon inheritViewBox component={UploadIcon} sx={{ fontSize: 37 }} />
            <Box>
              <Trans
                components={{
                  span: (
                    <Box
                      component="span"
                      sx={{ color: palette.primary.dark, fontWeight: 'fontWeightMedium', textDecoration: 'underline' }}
                    />
                  ),
                  a: (
                    <Box
                      component="a"
                      href="mailto:yearout@pelles.ai"
                      sx={{ color: palette.primary.dark, fontWeight: 'fontWeightMedium' }}
                      onClick={event => event.stopPropagation()}
                    />
                  ),
                }}
              >
                {t('uploadFiles.generalDropzone')}
              </Trans>
            </Box>
          </Box>

          {!import.meta.env.PROD && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: 110 }}>
              <DriveButton
                icon={<GoogleDriveIcon style={{ width: 40 }} />}
                text={t('uploadFiles.clouds.google')}
                onClick={onGoogleDriveClick}
              />
              <DriveButton
                icon={<OneDriveIcon style={{ width: 50 }} />}
                text={t('uploadFiles.clouds.onedrive')}
                onClick={onOneDriveClick}
              />
              <DriveButton
                disabled
                icon={<ProcoreIcon style={{ width: 40, height: 40 }} />}
                text={t('uploadFiles.clouds.procore')}
              />
              <DriveButton disabled icon={<AutodeskIcon style={{ width: 50 }} />} text={t('uploadFiles.clouds.autodesk')} />
            </Box>
          )}
        </Stack>
      </DropZoneArea>

      <CloudFilesDialog
        driveFilesType={openedDriveFilesType}
        onFileSelect={onFileSelect}
        onClose={() => setOpenedDriveFilesType('none')}
      />
    </>
  );
};

export default DocumentUpload;
